import { MENU, CURRENTNAV, RECORDLINK, SEARCH, SHOWSETTINGS, SETKEYMAP, NAVBAR } from '../../redux/Types';

export const setMenu = (menu: string) => {
  return {
    type: MENU,
    payload: menu,
  };
};

export const setCurrentNav = (data: any) => {
  localStorage.setItem('currentNav', data);
  return {
    type: CURRENTNAV,
    payload: data,
  };
};

export const recordLink = (data: any) => {
  return {
    type: RECORDLINK,
    payload: data,
  };
};

export const setOpenSearch = (data: any) => {
  return {
    type: SEARCH,
    payload: data,
  };
};

export const showSettings = () => {
  return {
    type: SHOWSETTINGS,
  };
};

export const mobileMenu = (data: any) => {
  return {
    type: NAVBAR,
    payload: data,
  };
};

export const setKeyMap = (data: any) => {
  return {
    type: SETKEYMAP,
    payload: data,
  };
};

// export type MainAction = ReturnType<
//   | typeof setKeyMap
//   | typeof mobileMenu
//   | typeof showSettings
//   | typeof setMenu
//   | typeof setCurrentNav
//   | typeof recordLink
//   | typeof setOpenSearch
// >;
