import { setCurrentNav, setOpenSearch, mobileMenu } from '../../redux/actions/mainActions';
import store from '../../redux/store';
import { THEME } from '../../redux/Types';

export const lastVisit = (lastVisited: any, path: string) => {
  if (path === '/') return lastVisited;
  localStorage.setItem('lastVisit', path);
  return path;
};

type Visited = {
  name: string;
  path: string;
};

export const parseLink = ({ name, path }: Visited, state: any) => {
  if (path === '/') return state;
  if (state[name]) {
    state[name].count++;
  } else {
    state[name] = { name, path, count: 1 };
  }
  localStorage.setItem('recordLink', JSON.stringify(state));
  return state;
};

export const getLocalJson = (local: string) => {
  let col = localStorage.getItem(local);
  if (col) return JSON.parse(col);
  else return null;
};

export const shiftToast = (payload: any, state: any) => {
  if (state.length === 20) {
    state.pop();
    state.unshift(payload);
  } else {
    state.unshift(payload);
  }
  localStorage.setItem('toast', JSON.stringify(state));
  return state;
};

export const removeToast = (payload: any, state: any) => {
  state.splice(payload, 1);
  localStorage.setItem('toast', JSON.stringify(state));
  return state;
};

export const shiftVal = (payload: any, state: any) => {
  if (state.length === 10) {
    const keys = Object.keys(state);
    delete state[keys[keys.length - 1]];
    state[payload.uniqueId] = payload;
  } else {
    state[payload.uniqueId] = payload;
  }
  return state;
};

export const applyTheme = (nextTheme: 'light' | 'dark', dontStore?: boolean) => {
  document.documentElement.setAttribute('color-mode', nextTheme);
  if (!dontStore) {
    store.dispatch({
      type: THEME,
      payload: nextTheme,
    });

    localStorage.setItem('theme', nextTheme);
  }
};

export const ActionMap: { [key: string]: () => void } = {
  'Client Menu': () => store.dispatch(setCurrentNav('client')),
  'Portfolio Menu': () => store.dispatch(setCurrentNav('main')),
  'G.Ledger Menu': () => store.dispatch(setCurrentNav('gl')),
  'Security Menu': () => store.dispatch(setCurrentNav('security')),
  Search: () => store.dispatch(setOpenSearch(false)),
  Navbar: () => store.dispatch(mobileMenu(true)),
};

export const keyMap: { key: string; name: string; route?: string; action?: true }[] = [
  {
    key: 'alt+c',
    name: 'Client Menu',
    action: true,
  },
  {
    key: 'alt+m',
    name: 'Portfolio Menu',
    action: true,
  },
  {
    key: 'alt+g',
    name: 'G.Ledger Menu',
    action: true,
  },
  {
    key: 'alt+s',
    name: 'Security Menu',
    action: true,
  },
  {
    key: 'shift+s',
    name: 'Search',
    action: true,
  },
  {
    key: 'alt+o',
    name: 'Navbar',
    action: true,
  },
  {
    key: 'alt+h',
    name: 'Home',
    route: '/',
  },
  {
    key: 'alt+p',
    name: 'Portfolios',
    route: '/portfolios',
  },
  {
    key: 'alt+r',
    name: 'Basic Report',
    route: '/basicreports',
  },
];
