import axios from 'axios';
import { agFont, agFontSize, url } from '../constantsApp';
import { QueryClient, QueryFunctionContext } from 'react-query';
import { handleError } from '../redux/actions/utils';
import store from '../redux/store';
import Toast from '../Utils/Toast/Toast';
import { TOGGLEACCOUNTHIDEMATCHED, SETBANKACCOUNTSORT, LOGOUTUSER } from '../ReactQueryConst';

// const alt = debounce(
//   () => Toast({ msg: 'You are viewing data offline', type: 'warning' }),
//   2000
// );

//This will exclude toast notification for url's in this array
const excludeUrl = [TOGGLEACCOUNTHIDEMATCHED, SETBANKACCOUNTSORT, LOGOUTUSER];

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      queryFn: async ({ queryKey }: QueryFunctionContext) => {
        const { data } = await axios.get(`${queryKey[0]}`);
        // console.log(data,'showing data -Godwin-');
        // if (headers['x-sw-cache'] === 'yes') alt();
        return data;
      },
      retry: (count: number, error: any): boolean => (!error.response && count < 10 ? true : false),
      refetchOnWindowFocus: process.env.NODE_ENV === 'production', //for Web,
      // retryDelay:5
    },
  },
});

export const applyFont = (value: string) => {
  document.documentElement.style.setProperty('--ag-font', value);
  localStorage.setItem('font', value);
};
export const applyFontSize = (value: string) => {
  document.documentElement.style.setProperty('--ag-font-size', value + 'px');
  localStorage.setItem('fontsize', value);
};
//https://thedutchlab.com/blog/using-axios-interceptors-for-refreshing-your-api-token
export const setup = () => {
  axios.defaults.baseURL = url;
  // axios.defaults.headers.post['Content-Type'] = 'text/plain';
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.interceptors.request.use(function (config) {
    const token = store.getState().auth.token;
    config.headers.authorization = token ? `Bearer ${token}` : '';
    return config;
  });
  axios.interceptors.response.use(
    (response) => {
      if (response.status === 200 && response.config.method !== 'get') {
        if (response.data.token) return response;
        if (response.data.status === true) {
          const splitUrl = response.config.url ? response.config.url : '';
          const reformUrl = splitUrl.split('/').join(',');
          const newReformUrl = excludeUrl.some((item) => reformUrl.includes(item));
          if (newReformUrl) {
            return response;
          }

          Toast({
            msg: response.data.detail,
            type: 'success',
            notify: response.data.detail === 'Success' ? false : true,
          });
        } else if (response.data.status === false) {
          Toast({
            msg: response.data.detail,
            type: 'warning',
            notify: true,
          });
        } else {
          if (response.data.detail) {
            Toast({ msg: response.data.detail, type: 'danger', notify: true });
          }
        }
        return response;
      } else {
        return response;
      }
    },
    (error) => {
      return Promise.reject(handleError(error));

      //Former implementation of refresh token
      //   const originalRequest = error.config;
      //   if (error.response.status === 401 && originalRequest.url !== 'refresh') {
      //     return axios
      //       .post('refresh', {
      //         token: store.getState().auth.token,
      //         refreshToken: store.getState().auth.refresh,
      //       })
      //       .then((x: any) => {
      //         if (x.status === 200) {
      //           store.dispatch(
      //             loginSuccess({
      //               token: x.data.token,
      //               refreshToken: x.data.refreshToken,
      //               fullName: x.data.fullName,
      //               emailAddress: x.data.emailAddress,
      //             })
      //           );
      //           axios.defaults.headers.common['authorization'] =
      //             'Bearer ' + x.data.token;
      //           originalRequest.headers['authorization'] =
      //             'Bearer ' + x.data.token;
      //           return axios(originalRequest);
      //         }
      //       })
      //       .catch((err: any) => {
      //         return Promise.reject(handleError(err));
      //       });
      //   } else {
      //     return Promise.reject(handleError(error));
      //   }
    }
  );
  applyFont(localStorage.getItem('font') || agFont);
  applyFontSize(localStorage.getItem('fontsize') || agFontSize.toString());
};
