import { shiftVal } from './utils';
import { RECEIVEVALSTATUS } from '../../redux/Types';

const initialState = {
  // @ts-ignore ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
  val: JSON.parse(localStorage.getItem('val') || null) || {},
  valState: 0,
  receiveValStatus: null,
};

const valuationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case 'CONNECTVAL':
      return {
        ...state,
        val: shiftVal(action.payload, { ...state.val }),
      };
    case 'DISCONNECTVAL':
      localStorage.removeItem('val');
      return {
        ...state,
        val: {},
      };
    case 'VALSTATE':
      return {
        ...state,
        valState: action.payload,
      };
    case RECEIVEVALSTATUS:
      return {
        ...state,
        receiveValStatus: action.payload,
      };
    default:
      return state;
  }
};

export default valuationReducer;
