import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import mainReducer from './mainReducer';
import generalLedger from './generalLedgerReducer';
import authReducer from './authReducer';
import valuationReducer from './valuationReducer';

const rootConfig = {
  key: 'root',
  storage,
  whitelist: [],
};
const mainConfig = {
  key: 'main',
  storage,
  whitelist: ['keyMap'],
};

const rootReducer = combineReducers({
  main: persistReducer(mainConfig, mainReducer),
  gl: generalLedger,
  auth: authReducer,
  valuation: valuationReducer,
});

// export type AppState = ReturnType<typeof rootReducer>;

export default persistReducer(rootConfig, rootReducer);
