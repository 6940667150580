// import env from '@beam-australia/react-env';
/* why?
we need to be able to set backend url and public_url in runtime not build time. 
It is a temp. soln. till cra fixes it.
https://github.com/facebook/create-react-app/issues/2353
https://github.com/andrewmclagan/react-env
*/

// export const xfundUrl = env('XFUND_URL');
// export const isPension = env('INDUSTRY')?.toUpperCase() === 'P' ? true : false;
// export const baseUrl = env('BASE_URL') ?? '';

// export const xfundUrl = process.env.REACT_APP_XFUND_URL;
// export const isPension = process.env.REACT_APP_INDUSTRY?.toUpperCase() === 'P' ? true : false;
// export const baseUrl = process.env.REACT_APP_BASE_URL ?? '';

//https://github.com/kunokdev/cra-runtime-environment-variables
export const xfundUrl = (window as any)?._env_.REACT_APP_XFUND_URL;
export const isPension = (window as any)?._env_.REACT_APP_INDUSTRY?.toUpperCase() === 'P' ? true : false;
export const baseUrl = (window as any)?._env_.REACT_APP_BASE_URL ?? '';
