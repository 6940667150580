import { AUTHFALSE, AUTHTRUE } from '../../redux/Types';
import { getLocalJson } from './utils';

const initialState = {
  authenticated: getLocalJson('authenticated'),
  token: localStorage.getItem('token'),
  expiryDate: localStorage.getItem('expiryDate'),
  // refresh: localStorage.getItem('refresh'),
  user: getLocalJson('user'),
};

const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case AUTHTRUE:
      return {
        ...state,
        authenticated: true,
        token: action.payload.token,
        expiryDate: action.payload.expiryDate,
        // refresh: action.payload.refresh,
        user: action.payload.user,
      };
    case AUTHFALSE:
      return {
        ...state,
        authenticated: false,
        token: null,
        expiryDate: null,
        // refresh: null,
        user: null,
      };
    default:
      return state;
  }
};

export default authReducer;
