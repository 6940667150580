import { createStore, compose } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension';

import { persistStore } from 'redux-persist';

import rootReducer from './reducers';

const initialState = {};

const composeEnhancers =
  process.env.NODE_ENV === 'production' ? compose : composeWithDevTools({ trace: true, traceLimit: 25 });

const store = createStore(rootReducer, initialState, composeEnhancers());

export const persistor = persistStore(store);

export default store;
