export const SELECTEDENTITY = 'SELECTEDENTITY';
export const MENU = 'MENU';
export const NAVBAR = 'NAVBAR';
export const RECORDLINK = 'RECORDLINK';
export const CURRENTNAV = 'CURRENTNAV';
export const SEARCH = 'SEARCH';
export const THEME = 'THEME';
export const SHOWSETTINGS = 'SHOWSETTINGS';
export const TOAST = 'TOAST';
export const CLEARTOAST = 'CLEARTOAST';
export const REMOVETOAST = 'REMOVETOAST';
export const AUTHFALSE = 'AUTHFALSE';
export const AUTHTRUE = 'AUTHTRUE';
export const RECEIVEVALSTATUS = 'RECEIVEVALSTATUS';
export const SETKEYMAP = 'SETKEYMAP';
