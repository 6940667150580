import { nanoid } from 'nanoid/non-secure';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import store from '../../redux/store';
import { TOAST } from '../../redux/Types';
import './Toast.scss';
import React from 'react';

export const sendNotification = (msg: string) => {
  const date = new Date();
  const minutes = date.getMinutes();
  const hours = date.getHours();
  const addPadding = (val: any) => String(val).padStart(2, '0');

  store.dispatch({
    type: TOAST,
    payload: {
      msg,
      time: `${addPadding(hours)}:${addPadding(minutes)}`,
      id: nanoid(),
    },
  });
};

export const Toast = ({
  duration = 2000,
  component,
  msg = 'Default message',
  type = 'default',
  notify = false,
}: {
  duration?: null | number;
  component?: (onClose?: () => void) => JSX.Element;
  msg?: string;
  type?: 'success' | 'success-outline' | 'default' | 'primary' | 'danger' | 'danger-outline' | 'warning';
  notify?: boolean;
}) => {
  const bgColor = `slick-alert alert-${type}`;
  let icon: any;

  if (notify) sendNotification(msg);

  type === 'success' || type === 'success-outline'
    ? (icon = 'alert-icon outline feather icon-check-circle')
    : (icon = '');

  toast(
    ({ closeToast }) =>
      component ? (
        component(closeToast)
      ) : (
        <div className={bgColor}>
          <i className={icon} />
          <span>{msg}</span>
          <i className="feather icon-x alert-icon" onClick={closeToast}></i>
        </div>
      ),
    {
      position: toast.POSITION.TOP_CENTER,
      autoClose: duration === null ? false : duration,
      hideProgressBar: true,
    }
  );
};

export default Toast;
