import { AUTHTRUE, AUTHFALSE } from 'redux/Types';
import { queryClient } from 'Utils/appUtil';

export const loginSuccess = ({
  token,
  // refreshToken,
  fullName,
  emailAddress,
  expiryDate,
}: any) => {
  localStorage.setItem('token', token);
  localStorage.setItem('expiryDate', expiryDate);
  localStorage.setItem('user', JSON.stringify({ fullName, emailAddress }));
  // localStorage.setItem('refresh', refreshToken);
  localStorage.setItem('authenticated', 'true');
  return {
    type: AUTHTRUE,
    payload: {
      user: { fullName, emailAddress },
      token, //refresh: refreshToken
      expiryDate,
    },
  };
};

export const loginFail = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  // localStorage.removeItem('refresh');
  localStorage.removeItem('authenticated');
  //clear react-querycache
  queryClient.clear();
  return { type: AUTHFALSE };
};

export const logout = () => {
  return loginFail();
};
