import { xfundUrl } from './config';
import { getDate } from './pages/utils';

export const agFontSize = 10;
export const agFont = "'Open Sans', sans-serif";

export const assetLimits = {
  code: 'string',
  objectiveId: 'string',
  objectiveName: 'string',
  riskProfileId: 0,
  transId: 0,
};

export const riskRating = {
  agencyCode: 'string',
  agencyName: 'string',
  transId: 0,
};

export const ratingCode = {
  transId: 0,
  uniqueId: 'string',
  agencyCode: 'string',
  codeClass: 'string',
  ratingCode: 'string',
  scale: 0,
};

export const riskRatingLimitsDefs = {
  codeClass: 'string',
  groupId: 'string',
  description: 'string',
  itemType: 'string',
  limit: 0,
  objectiveId: 0,
  ratingCode: 'string',
  scale: 0,
  transId: 0,
};

export const FundAccountingRulesDefs = {
  process: 'string',
  action: 'string',
  acctNo: 'string',
  amount: 0,
  order: 0,
  transId: 0,
};

export const FundAccountingRulesDefsZ = {
  classId: 'string',
  process: 'string',
  action: 'string',
  acctNo: 'string',
  amount: 0,
  order: 0,
  transId: 0,
};

export const GlReportDetailsDefs = {
  itemType: 'string',
  itemDescription: 'string',
  itemColumn: 'string',
  itemNature: 'string',
  itemFormular: 'string',
  noteCode: 'string',
  fontstyle: 'string',
  effects: 'string',
  itemId: 'string',
  itemOrder: 0,
  reportId: 'string',
  reportType: 'string',
  profileId: 'string',
  transId: 0,
};

export const GlScheduleEntries = {
  transId: 0,
  profileId: 'profileId',
  code: 'code1',
  transType: 'Trans1',
  action: 'action1',
  acctNo: 'account',
  amount: '20',
};

export const BankAccountDefs = {
  accountName: 'string',
  accountNumber: 'string',
  allowOverdraft: 'bool',
  bankRegulatoryCode: 'string',
  companyId: 0,
  currency: 'string',
  defaultAccount: 'bool',
  enableInt: 'bool',
  glAccount: 'string',
  glFxAccount: 'string',
  ignoreCash: 'bool',
  instrumentId: 'string',
  intInstrumentId: 'string',
  overdraftLimit: 0,
  schemeId: 0,
  transId: 0,
};

export const StocksDefs = {
  schemeId: 0,
  cscsNo: 'string',
  tradeDate: 'date',
  settlementDate: 'date',
  transType: 'string',
  companyId: 0,
  bankAccountNo: 'string',
  instrumentId: 'string',
  broker: 'string',
  shares: 0,
  originalPrice: 0,
};

export const StocksDefsB = {
  schemeId: 0,
  cscsNo: 'string',
  tradeDate: 'date',
  settlementDate: 'date',
  transType: 'string',
  companyId: 0,
  bankAccountNo: 'string',
  instrumentId: 'string',
  broker: 'string',
  shares: 0,
  originalPrice: 0,
  secFees: 0,
  brokerageFees: 0,
  stampFees: 0,
  otherFees: 0,
  nseFees: 0,
  cscsFees: 0,
  vatBrokerage: 0,
  vatNse: 0,
  vatSec: 0,
  vatCscs: 0,
  vatOtherFees: 0,
};

export const BuyBondsDef = {
  schemeId: 0,
  instrumentId: 'string',
  companyId: 0,
  bondSource: 'string',
  tradeDate: 'date',
  settlementDate: 'date',
  faceValue: 0,
  cleanPpu: 0,
  bankAccountNo: 'string',
  counterPartyId: 0,
  customerId: 0,
};

export const capitalizationAmortizationDef = {
  schemeId: 0,
  instrumentId: 'string',
  transType: 'string',
  description: '',
  companyId: 0,
  amount: 0,
  startDate: 'date',
  valueDate: 'date',
  tenor: 0,
  bankAccountNo: 'string',
};

export const BuyMoneyDefs = {
  schemeId: 0,
  instrumentId: 'string',
  companyId: 0,
  faceValue: 0,
  interestRate: 0,
  tradeDate: 'date',
  settlementDate: 'date',
  tenor: 0,
  counterPartyId: 0,
  bankAccountNo: 'string',
  chargeFee: 'bool',
  witholdingTaxCode: 'string',
  customerId: 0,
  interestPaymentProfileCode: 'string',
  paymentAmount: 0,
};

export const SellMoneyDefs = {
  schemeId: 0,
  initiatingId: 0,
  // instrumentId: 'string',
  initiateLetters: 'bool',
  bankAccountNo: 'string',
  // companyId: 0,
  counterPartyId: 0,
  settlementDate: 'date',
  faceValue: 0,
  discountRate: 0,
  chargeId: 0,
};

export const BuyRealEstateDefs = {
  schemeId: 0,
  instrumentId: 'string',
  companyId: 0,
  valueDate: 'date',
  bankAccountNo: 'string',
  amount: 0,
};

export const ReconciliationDefs = {
  // accountNumber: 'string',
  valueDate: 'date',
  narration: 'string',
  amount: 0,
  referenceNo: 'string',
};

export const WalletProfileModalDefs = {
  transId: 0,
  currencyCode: 'string',
  processType: 'string',
  cashId: 'string',
};

export const ProductSubscriptionDefs = {
  liabilityProductId: 'string',
  customerId: 0,
  settlementDate: 'date',
  faceValue: 0,
  interestRate: 0,
  tenor: 0,
  autoRollover: 'bool',
  autoRolloverTerm: 'string',
  scheduledAmountType: 'string',
  scheduledPaymentAmount: 0,
  scheduledPaymentFrequency: 0,
};

export const PublishProfilesDefs = {
  acctNo: 'string',
  foreignAcctNo: 'string',
  beginPeriod: 'string',
  enabled: 'bool',
  transId: 0,
};

export const CashIODefs = {
  schemeId: 0,
  classId: 'string',
  transType: 'string',
  description: 'string',
  valueDate: 'date',
  amount: 0,
  bankAccountNo: 'string',
};

export const CashLiabilityDefs = {
  schemeId: 0,
  customerId: 0,
  bankAccountNo: 'string',
  classId: 'string',
  description: 'string',
  amount: 0,
  valueDate: 'date',
};

export const OtherAssetDefs = {
  schemeId: 0,
  instrumentId: 'string',
  transType: 'string',
  description: 'string',
  companyId: 0,
  amount: 0,
  valueDate: 'date',
  bankAccountNo: 'string',
};

export const AccrueDividendDefs = {
  schemeIds: 0,
  companyId: 0,
  overrideInstrumentId: 'string',
  exDivDate: 'date',
  expectedPaymentDate: 'date',
  dps: 0,
  overridingQuantity: 0,
  withHoldingTax: 0,
};

export const AccountingUnitsDefs = {
  schemeId: 0,
  transType: 'string',
  description: 'string',
  amount: 0,
  transUnits: 0,
  valueDate: 'date',
  bankAccountNo: 'string',
};

export const journalDefs = {
  acctNo: 'string',
  debit: 0,
  credit: 0,
  transDate: 'string',
  narration: 'string',
  referenceNo: 'string',
  groupId: 'string',
  transId: 0,
};

export const GlRecurringPostingDefs = {
  deptId: 'string',
  acctNo: 'string',
  debit: 0,
  credit: 0,
  transDate: 'string',
  narration: 'string',
  referenceNo: 'string',
  transId: 0,
  code: '8E229',
  companyId: '00001',
};

export const portfolioDefs = {
  disabled: 'bool',
  schemeType: 'int',
  discretionary: 'string',
  objectiveId: 'int',
  groupId: 'string',
  portfolioName: 'string',
  portfolioFullName: 'string',
  fundAlias: 'string',
  yearEndMonth: 'string',
  defaultCurrency: 'string',
  processingDate: 'date',
  bidRatio: 'int',
  offerRatio: 'int',
  enforceGl: 'bool',
  glCompanyId: 'string',
  defaultGlDeptCode: 'string',
  minimumInvestment: 'int',
  minimumRedemptionPeriod: 'int',
  penaltyOnEarlyRedemption: 'bool',
  penaltyOnRedemptionType: 'string',
  penaltyRate: 'int',
  publishPrice: 'bool',
  matchUnitsToClient: 'string',
  companyId: 'int',
  incomeLiability: 'string',
  portfolioShortCode: 'string',
};

export const clientDefs = {
  clientType: 'string',
  surname: 'string',
  firstname: 'string',
  othernames: 'string',
  dateOfBirthOrIncorporation: 'date',
  registeredCompanyNumber: 'string',
  contactPerson: 'string',
  emailAddress: 'string',
  bvn: 'string',
  chn: 'string',
  crmId: 'string',
  clientId: 0,
  fullname: 'string',
  alternateEmailAddress: 'string',
  approved: 'bool',
  prospectiveClient: 'bool',
  agentCode: 'string',
  diasporaClient: 'bool',
  maritalStatus: 'string',
  nationality: 'string',
  occupation: 'string',
  religion: 'string',
  mothersMaidenName: 'string',
  placeOfBirth: 'string',
  address1: 'string',
  address2: 'string',
  city: 'string',
  state: 'string',
  country: 'string',
  zipcode: 'string',
  mobileNumber: 'string',
  identificationType: 'string',
  identificationNumber: 'string',
  nin: 'string',
  houseHoldName: 'string',
  partnerCode: 'string',
  classCode: 'string',
  tier: 0,
  identificationExpiry: 'date',
  accountNo: 'string',
  gender: 'string',
  clientGroupId: 0,
  // kycCertified: 'bool',
  // kycCertifiedBy: 'string',
  // kycCertifiedDate: 'date',
  // enableOnlineAccess: 'bool',
  // onlineAccessDate: 'date',
  // lastAccess: 'date',
};

export const assetClassLimitDefs = {
  description: 'string',
  limitType: 'int',
  lowerLimit: 'int',
  upperLimit: 'string',
  inexhaustive: 'string',
  groupId: 'string',
  objectiveId: 'int',
  transId: 0,
};

export const bondIssuerPaymentScheduleDefs = {
  transId: 'int',
  companyId: 'int',
  transDate: 'date',
  faceValue: 'int',
  interest: 'int',
};

export const cscsDefs = {
  cscsNo: 'string',
  preferred: 'bool',
  scope: 'string',
  approved: 'bool',
  schemeId: 0,
  transId: 0,
  excludeFromDividend: 'bool',
  shortNote: 'string',
};

export const revaluationDefs = {
  schemeId: 0,
  customerId: 0,
  instrumentId: 'string',
  companyId: 0,
  transType: 'string',
  description: 'string',
  valueDate: 'date',
  currentValue: 0,
};

export const glEntityDefs = {
  companyName: 'string',
  postingMethod: 'string',
  currentPeriod: 'string',
  defaultCurrency: 'string',
  accountProfile: 'string',
  period12: 'string',
  retainedAccount: 'string',
};

export const ModelPortfolioDefs = {
  groupId: 'string',
  description: 'string',
  allocation: 0,
  benchmark: 'string',
  objectiveId: 0,
  transId: 0,
  benchmarkAllocation: 0,
};

export const chartOfAccountDefs = {
  acctNo: 'string',
  profileId: 'string',
  acctDescription: 'string',
  accountCategory: 'string',
  accountGroup: 'string',
  status: 'string',
  transId: 0, //int
};

export const chartOfAccountHeaderDefs = {
  profileId: 'string',
  acctNo: 'string',
  acctDescription: 'string',
  accountCategory: 'string',
  transId: 0, //int
};

export const reconciliationBatchDefs = {
  // groupId: 'string',
  transDate: '2021-05-24T14:47:09.792Z',
  narration: 'string',
  referenceNo: 'string',
  amount: 0,
  matched: 'bool',
  transId: 0,
  comment: 'string',
  // matchId: 'string',
  // tableType: 0,
  // mark4Marching: true,
};

export const approvalOptions: any = {
  order: [
    { name: 'Stock Order', value: 'SO' },
    { name: 'Money Market Order', value: 'MO' },
    { name: 'Bond Order', value: 'BO' },
  ],
  trade: [
    { name: 'Buy Money Market', value: 'M' },
    { name: 'Buy Bonds', value: 'B' },
    { name: 'Buy/Sell Stock', value: 'C' },
    { name: 'Sell/Liquidate Money Market', value: 'MSL' },
    { name: 'Sell/Liquidate Bonds', value: 'BSL' },
  ],
  corporateaction: [
    { name: 'Accrued Dividend', value: 'AD' },
    { name: 'Receive Dividend', value: 'RD' },
    { name: 'Declare Bonus', value: 'DB' },
  ],
  fixedincomeaction: [
    { name: 'Coupon On Bond', value: 'CP' },
    { name: 'Reclassify Money Market', value: 'RM' },
    { name: 'Reclassify Bond', value: 'RB' },
  ],
  otherbasictransactions: [
    { name: 'Cash Entries', value: 'CE' },
    { name: 'Inter-bank Transactions', value: 'IB' },
    { name: 'Capitalised Interest', value: 'CI' },
    { name: 'Capitalization', value: 'CA' },
    { name: 'Other Assets/(Liability)', value: 'OC' },
    { name: 'Other Assets/(Liability) Revaluation', value: 'OR' },
    { name: 'Fee Payment', value: 'FP' },
    { name: 'Fee Adjustment', value: 'FA' },
    // { name: 'Accounting Units', value: 'AU' },
  ],
  // subscriptionredemption: [
  //   { name: 'New Portfolios', value: 'NPS' },
  //   { name: 'Change Processing Date', value: 'CPD' },
  //   { name: 'Withdrawal Restriction', value: 'WR' },
  //   { name: 'Stocks / Products / Institutions', value: 'SPI' },
  //   { name: 'Portfolio Fee Matrix', value: 'FEE' },
  // ],
  nontransactional: [
    { name: 'New Portfolios', value: 'NPS' },
    { name: 'Change Processing Date', value: 'CPD' },
    { name: 'Withdrawal Restriction', value: 'WR' },
    { name: 'Stocks / Products / Institutions', value: 'SPI' },
    { name: 'Portfolio Fee Matrix', value: 'FEE' },
  ],
};

const constants = {
  defaultColumnDefs: {
    // autoSizePadding: -10,
    filter: true,
    resizable: true,
    sortable: true,
    cellRenderer: 'agAnimateShowChangeCellRenderer',
    headerCheckboxSelectionFilteredOnly: true, // minWidth: 100
  },
  portfolioTypes: {
    portfolioName: 'string',
    portfolioFullName: 'string',
    fundAlias: 'string',
    schemeType: 0,
    objectiveId: 0,
    discretionary: 'bool',
    groupId: 'string',
    processingDate: 'date',
    defaultCurrency: 'string',
    yearEndMonth: 'string',
    clientId: 0,
    startDate: 'date',
    endDate: 'date',
    companyId: 0,
    publishPrice: 'bool',
    incomeLiability: 'string',
    enforceGl: 'bool',
    glCompanyId: 'string',
    defaultGlDeptCode: 'string',
    disabled: 'bool',
    bidRatio: 0,
    offerRatio: 0,
    pfcCode: 'string',
    rmasSchemeCode: 'string',
    rmasAssociationCode: 'string',
    userId: 0,
    schemeId: 0,
  },
};

export const PerformanceBenchmarkDefs = {
  transId: 'number',
  benchmarkCode: 'string',
  benchmarkValue: 'string',
  // benchmarkCode: 'string' || 'number',
  // benchmarkValue: 'string' || 'number',
  transDate: 'date',
};

export const kendoStyles = [
  { text: 'Default', value: 'default' },
  { text: 'Black', value: 'black' },
  { text: 'Blue Opal', value: 'blueopal' },
  { text: 'Material', value: 'material' },
  { text: 'Metro', value: 'metro' },
  { text: 'Silver', value: 'silver' },
  { text: 'Metro Black', value: 'metroblack' },
  { text: 'Bootstrap', value: 'bootstrap' },
  { text: 'Uniform', value: 'uniform' },
  { text: 'High Contrast', value: 'highcontrast' },
  { text: 'Moonlight', value: 'moonlight' },
];

export const marketPricesOptions = {
  stockprice: {
    name: 'Stock Price',
    get: 'getstockprice',
    upload: 'uploadstockprices',
    dataType: 'S',
    initialState: {
      symbol: '',
      closePrice: '', //int

      transDate: getDate(),
      transId: 0,
    },
  },

  bondprice: {
    name: 'Bond Price',
    get: 'getbondprice',
    upload: 'uploadbondprices',
    dataType: 'B',
    initialState: {
      symbol: '',
      closePrice: '', //int
      closeYield: '', //int
      outstandingValue: '', //int

      transDate: getDate(),
      transId: 0,
    },
  },
  treasurybillrate: {
    name: 'Treasury Bill Rate',
    get: 'gettbillrates',
    upload: 'uploadtbillrates',
    dataType: 'T',
    initialState: {
      // class: '',
      institution: '',
      maturityDate: '',
      closingRate: '', //int
      closingYield: '', //int
      closingPrice: '', //int
      transDate: getDate(),
      transId: 0, //int
    },
  },
  moneymarketrate: {
    name: 'Money Market Rate',
    get: 'getmoneymarketrates',
    upload: 'uploadmoneymarketrates',
    dataType: 'M',
    initialState: {
      // class: '',
      institution: '',
      maturityDate: '',
      closingRate: '', //int
      closingYield: '', //int
      closingPrice: '', //int
      transDate: getDate(),
      transId: 0,
    },
  },
  forexrates: {
    name: 'Forex Rates',
    get: 'getforexrates',
    upload: 'uploadforexrates',
    dataType: 'X',
    initialState: {
      primaryCode: '',
      secondaryCode: '',
      rate: '', //int

      transDate: getDate(),
      transId: 0,
    },
  },
  benchmark: {
    name: 'Benchmark',
    get: 'getbenchmarkvalues',
    upload: 'uploadbenchmark',
    dataType: 'H',
    initialState: {
      benchmarkCode: '',
      benchmarkValue: 0,
      transDate: getDate(),
      transId: 0,
    },
  },
  deposit: {
    name: 'Deposit Rates',
    get: 'getdepositrates',
    upload: 'uploadeffectivedepositrate',
    dataType: 'D',
    initialState: {
      companyId: 0,
      tenor: 0,
      interestRate: 0,
      description: '',
      transDate: getDate(),
      transId: 0,
    },
  },
};

type ReportType = {
  name: string;
  text: string;
  desc?: string;
  type: string;
  group?: {
    name: string;
    text: string;
    desc?: string;
  }[];
};

export const reportTypes: ReportType[] = [
  {
    name: '',
    text: 'Valuation',
    desc: 'This report shows the asset and liability position of a portfolio as at a required date',
    type: 'basicreports',
    group: [
      {
        name: 'valuationsummary.trdp',
        text: 'Valuation Summary',
        desc: 'This report shows the summarised asset and liability position of a portfolio as at a date',
      },
      {
        name: 'valuationlisting.trdp',
        text: 'Valuation Listing',
        desc: 'This report shows the detailed asset and liability position of a portfolio as at a date',
      },
    ],
  },
  {
    name: 'portfolioincome.trdp',
    text: 'Portfolio Income',
    desc: 'This report shows the earned income of a portfolio within a period',
    type: 'basicreports',
  },
  {
    name: 'cashhistory.trdp',
    text: 'Cash History',
    desc: 'This report shows the cash activity of a portfolio within a period',
    type: 'basicreports',
  },
  {
    name: 'walletcashhistory.trdp',
    text: 'Wallet Cash Activities',
    desc: 'This report shows the wallet activities within a period',
    type: 'basicreports',
  },
  {
    name: 'fundvaluemovement.trdp',
    text: 'Fund Value Movement',
    desc: 'This report places the portfolio balances of a portfolio side by side within a period',
    type: 'basicreports',
  },
  {
    name: '',
    text: 'Accrued Income',
    desc: 'This report shows the accrued income/coupon of a portfolio',
    type: 'basicreports',
    group: [
      {
        name: 'bondsaccruedincome.trdp',
        text: 'Bonds Accrued Income',
        desc: 'This report shows the detail of accrued coupon of a portfolio within a period',
      },
      {
        name: 'moneymarketaccruedinterest.trdp',
        text: 'Money Market Accrued Income',
        desc: 'This report shows the detail of accrued interest of a portfolio within a period',
      },
    ],
  },
  {
    name: 'accountingunitssummary.trdp',
    text: 'Accounting Units Summary',
    desc: 'This report shows the accounting units position of a portfolio within a period',
    type: 'basicreports',
  },

  {
    name: '',
    text: 'Money Market Schedule',
    desc: 'This report shows the HTM/AFS and HFT position of a portfolio',
    type: 'basicreports',
    group: [
      {
        name: 'moneymarketschedulemarktomarket.trdp',
        text: 'Money Market Schedule - Mark To Market',
        desc: 'This report shows the money market MTM position of a portfolio',
      },
      {
        name: 'moneymarketscheduleheldtomaturity.trdp',
        text: 'Money Market Schedule - Held To Maturity',
        desc: 'This report shows the Money Market Held to Maturity position of a portfolio',
      },
      {
        name: 'treasurybillmarktomarket.trdp',
        text: 'Treasury Bill - Mark To Market',
        desc: 'This report shows the Treasury bill MTM position of a portfolio',
      },
      {
        name: 'treasurybillheldtomaturity.trdp',
        text: 'Treasury Bill - Held To Maturity',
        desc: 'This report shows the Treasury bill HTM position of a portfolio',
      },
    ],
  },
  {
    name: '',
    text: 'Bond Schedule',
    desc: 'This report shows the Bond Position of a portfolio as at a date',
    type: 'basicreports',
    group: [
      {
        name: 'bondschedulemarktomarket.trdp',
        text: 'Bond Schedule - Mark To Market',
        desc: 'This report shows the Bond MTM Position of a portfolio as at a date',
      },
      {
        name: 'bondscheduleheldtomaturity.trdp',
        text: 'Bond Schedule - Held To Maturity',
        desc: 'This report shows the Bond HTM Position of a portfolio as at a date',
      },
    ],
  },
  {
    name: 'equityschedule.trdp',
    text: 'Equity Schedule',
    desc: 'This report shows the Equity Position of a portfolio as at a date',
    type: 'basicreports',
  },

  {
    name: 'timeweightedreturns.trdp',
    text: 'Time Weighted Returns',
    desc: 'This report shows the detail Time Weighted Returns of a portfolio within a period',
    type: 'performancereports',
  },
  {
    name: 'timeweightedreturnsummary.trdp',
    text: 'Time Weighted Return Summary',
    desc: 'This report shows the summary of Time Weighted Returns of multiple portfolios within a period',
    type: 'performancereports',
  },
  {
    name: 'performanceweightedreturn.trdp',
    text: 'Performance Weighted Returns',
    desc: 'Investment Performance Weighted Return Analysis',
    type: 'performancereports',
  },
  {
    name: 'performanceattribution.trdp',
    text: 'Performance Attribution Analysis',
    desc: 'Investment Performance Attribution Analysis',
    type: 'performancereports',
  },
  {
    name: 'stockhistoricalcostanalysis.trdp',
    text: 'Stock Historical Cost Analysis',
    desc: 'This report shows stock year to date and inception to date cost position of a portfolio',
    type: 'performancereports',
  },
  {
    name: 'consolidatebondschedule.trdp',
    text: 'Consolidated Bond Schedule',
    desc: 'This report shows the bond consolidated position of a portfolio as at a date',
    type: 'performancereports',
  },
  {
    name: 'stockgainerslosers.trdp',
    text: 'Stock Gainers and Losers',
    desc: 'This report shows the top 5 equity gain/loss of a portfolio',
    type: 'performancereports',
  },
  {
    name: 'fixedincomeyearlymaturityprofile.trdp',
    text: 'Fixed Income Yearly Maturity Profile',
    desc: 'This report shows the yearly maturity profile schedule of a portfolio',
    type: 'performancereports',
  },
  {
    name: 'fixedincomematuritybucket.trdp',
    text: 'Fixed Income Maturity Bucket',
    desc: 'This report shows the maturity profile of a portfolio',
    type: 'performancereports',
  },
  {
    name: 'fixedincomewaywadsummary.trdp',
    text: 'Fixed Income WAY/WAD Summary',
    desc: 'This report shows the portfolio WAY and WAD as at a date',
    type: 'performancereports',
  },
  {
    name: 'bondfairvaluemovement.trdp',
    text: 'Bond Fair Value Movement',
    desc: 'This report shows the fair value gain/loss of a portfolio within a period',
    type: 'performancereports',
  },
  {
    name: 'financialassetmarkedtomarket.trdp',
    text: 'Financial Asset Marked to Market',
    desc: 'This report shows the Market to Market position of a portfolio financial instruments',
    type: 'performancereports',
  },
  {
    name: 'reportpresentationprofile.trdp',
    text: 'Report Presentation Profile',
    desc: 'This report shows the position of the predefined template as at a date',
    type: 'performancereports',
  },
  {
    name: 'maturityladderanalysis.trdp',
    text: 'Maturity Ladder Analysis',
    desc: 'This report compares the liabilty maturity and asset maturity as at a date',
    type: 'performancereports',
  },
  {
    name: '',
    text: 'Sale - Realised Gain/(Loss)',
    desc: 'This report shows the portfolio realised gains/loss within a period',
    type: 'basicreports',
    group: [
      {
        name: 'moneymarketrealisedgainorloss.trdp',
        text: 'Money Market',
        desc: 'This report shows the money market realised gains/loss within a period',
      },
      {
        name: 'bondrealisedgainorloss.trdp',
        text: 'Bonds',
        desc: 'This report shows the bond realised gains/loss within a period',
      },
      {
        name: 'equityrealisedgainorloss.trdp',
        text: 'Equity',
        desc: 'This report shows the equity realised gains/loss within a period',
      },
    ],
  },
  {
    name: 'assetclasslimitschedule.trdp',
    text: 'Asset Class Limit Schedule',
    desc: 'This report shows the asset class limit positions of a portfolio as at a date',
    type: 'risk&compliancereports',
  },
  {
    name: 'assetclasslimituncapped.trdp',
    text: 'Variable Instruments Limit',
    desc: 'This report shows the asset class limit positions of a portfolio as at a date',
    type: 'risk&compliancereports',
  },
  {
    name: 'strategicassetallocation.trdp',
    text: 'Strategic Asset Allocation (SAA)',
    desc: 'This report shows the SAA positions of a portfolio as at a date',
    type: 'risk&compliancereports',
  },
  {
    name: 'issuerexposureschedule.trdp',
    text: 'Issuer Exposure Schedule',
    desc: 'This report shows the exposure to issuer positions of a portfolio as at a date',
    type: 'risk&compliancereports',
  },
  {
    name: 'industrysectorexposure.trdp',
    text: 'Industry Sector Exposure',
    desc: 'This report shows the exposure to sector positions of a portfolio as at a date',
    type: 'risk&compliancereports',
  },
  {
    name: 'portfoliomodelanalysis.trdp',
    text: 'Portfolio Model Analysis',
    desc: 'This report shows compares the portfolio model with actual positions as at a date',
    type: 'risk&compliancereports',
  },
  {
    name: 'counterpartylimit.trdp',
    text: 'Counter Party Limit',
    desc: 'This report shows the counter party limit of a portfolio as at a date',
    type: 'risk&compliancereports',
  },
  {
    name: 'counterpartyglobalimit.trdp',
    text: 'Counter Party Global Limit',
    desc: 'This report shows the counter party global limit as at a date',
    type: 'risk&compliancereports',
  },
  {
    name: 'issuercreditriskratinglimit.trdp',
    text: 'Issuer Credit Risk Rating Limit',
    desc: 'This report shows the issuer credit risk rating limit with the actual positions',
    type: 'risk&compliancereports',
  },
  {
    name: 'equitybrokeranalysis.trdp',
    text: 'Equity Broker Analysis',
    desc: 'This report shows the positions of trades with various brokers',
    type: 'risk&compliancereports',
  },
  {
    name: 'equitybrokerfundanalysis.trdp',
    text: 'Equity Broker Fund Analysis',
    desc: 'This report shows the positions of portfolio trades with various brokers',
    type: 'risk&compliancereports',
  },
  {
    name: 'compliancebreachanalysis.trdp',
    text: 'Compliance Breach Analysis',
    desc: 'This report shows the compliance rule area of breach if any',
    type: 'risk&compliancereports',
  },
  {
    name: '',
    text: 'Management Fees',
    desc: 'This report shows the management fee positions of a portfolio',
    type: 'basicreports',
    group: [
      {
        name: 'feesummaryschedule.trdp',
        text: 'Management Fee Movement',
        desc: 'This report shows the management fee summary positions of a portfolio',
      },
      {
        name: 'feedailyaccural.trdp',
        text: 'Daily Accural',
        desc: 'This report shows the management fee daily accrual of a portfolio',
      },
      {
        name: 'portfoliofeeincome.trdp',
        text: 'Earned Income Schedule',
        desc: 'This report shows the earned income schedule based on accrued management fee in a portfolio',
      },
      {
        name: 'incentivefeeschedule.trdp',
        text: 'Incentive Fee Schedule',
        desc: 'This report shows the incentive fee schedule of a portfolio',
      },
      {
        name: 'effectivefeerate.trdp',
        text: 'Effective Fee Rate',
        desc: 'This report calculates the effective management fee rate within a period',
      },
    ],
  },
  {
    name: 'fundmaturityprofile.trdp',
    text: 'Fund Maturity Profile',
    desc: 'Fund Maturity Profile',
    type: 'basicreports',
  },
  {
    name: 'stockactivity.trdp',
    text: 'Stock Activity',
    desc: 'This report shows the stock transaction activities and cost price over a specified period',
    type: 'basicreports',
  },
];

export const monthList = [
  { name: 'January', value: '01' },
  { name: 'February', value: '02' },
  { name: 'March', value: '03' },
  { name: 'April', value: '04' },
  { name: 'May', value: '05' },
  { name: 'June', value: '06' },
  { name: 'July', value: '07' },
  { name: 'August', value: '08' },
  { name: 'September', value: '09' },
  { name: 'October', value: '10' },
  { name: 'November', value: '11' },
  { name: 'December', value: '12' },
];

export const url = xfundUrl;
//export const url = "https://localhost/api/v1";
//export const url = "http://localhost:5000/api/v1";
//export const url = "https://xfundcoreapi.azurewebsites.net/api/v1";
//export const url = "https://xfund.itapsolutions.com/api/v1";

export default constants;
