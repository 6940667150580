import React from 'react';

class ErrorBoundary extends React.Component<
  any,
  { error: any; errorInfo: any }
> {
  constructor(props: any) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.errorInfo) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            placeItems: 'center',
          }}
        >
          <h3>Something went wrong.</h3>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
          <div
            style={{ color: 'blue', cursor: 'pointer' }}
            onClick={() => window.location.reload()}
          >
            Reload this page
          </div>
          {/* add a form to rpeort issue */}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
