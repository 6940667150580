import { Action, Reducer } from 'redux';
import {
  MENU,
  CURRENTNAV,
  RECORDLINK,
  TOAST,
  CLEARTOAST,
  REMOVETOAST,
  THEME,
  SEARCH,
  SHOWSETTINGS,
  SETKEYMAP,
  NAVBAR,
} from '../../redux/Types';
import { parseLink, lastVisit, getLocalJson, shiftToast, removeToast, applyTheme, keyMap } from './utils';

const getTheme = () => {
  const x = localStorage.getItem('theme');
  const y = x === 'dark' ? x : 'light';
  if (x !== 'light') applyTheme(y, true);
  return y;
};

export type KeyMap = { key: string; name: string; route?: string; action?: true };
export interface MainState {
  readonly showMenu: string | null;
  readonly currentNav: string | null;
  readonly lastVisited: string | null;
  readonly recordLink: { [key: string]: any } | null;
  readonly toast: string[];
  readonly theme: 'light' | 'dark';
  readonly openSearch: boolean;
  readonly showSettings: boolean;
  readonly keyMap: KeyMap[];
  readonly isNavOpen: boolean;
  readonly kendo: string;
  readonly joyride: boolean;
}

const initialState: MainState = {
  showMenu: null,
  currentNav: localStorage.getItem('currentNav'),
  lastVisited: localStorage.getItem('lastVisit'),
  recordLink: getLocalJson('recordLink'),
  toast: getLocalJson('toast') || [],
  theme: getTheme(),
  openSearch: true,
  showSettings: false,
  // keyMap: JSON.parse(localStorage.getItem('keyMap')!) ?? keyMap,
  keyMap: keyMap,
  isNavOpen: false,
  kendo: localStorage.getItem('kendo') || 'uniform',
  joyride: getLocalJson('joyride'),
};

const mainReducer: Reducer<MainState, Action> = (state = initialState, action: any): MainState => {
  switch (action.type) {
    case MENU:
      return {
        ...state,
        showMenu: action.payload,
      };
    case CURRENTNAV:
      return {
        ...state,
        currentNav: action.payload,
      };
    case RECORDLINK:
      return {
        ...state,
        lastVisited: lastVisit(state.lastVisited, action.payload.path),
        recordLink: parseLink(action.payload, {
          ...state.recordLink,
        }),
      };
    case TOAST:
      return {
        ...state,
        toast: shiftToast(action.payload, [...state.toast]),
      };
    case CLEARTOAST:
      localStorage.removeItem('toast');
      return {
        ...state,
        toast: [],
      };
    case REMOVETOAST:
      return {
        ...state,
        toast: removeToast(action.payload, [...state.toast]),
      };
    case THEME:
      //light || dark
      return {
        ...state,
        theme: action.payload,
      };
    case SEARCH:
      return {
        ...state,
        openSearch: action.payload,
      };
    case SHOWSETTINGS:
      return {
        ...state,
        showSettings: !state.showSettings,
      };
    case SETKEYMAP:
      return {
        ...state,
        keyMap: action.payload,
      };
    case NAVBAR:
      return {
        ...state,
        isNavOpen: action.payload,
      };
    case 'KENDO':
      localStorage.setItem('kendo', action.payload);
      return {
        ...state,
        kendo: action.payload,
      };
    case 'JOYRIDE':
      localStorage.setItem('joyride', 'true');
      return {
        ...state,
        joyride: true,
      };
    default:
      return state;
  }
};

export default mainReducer;
